


















































































import Component from 'vue-class-component';
import DateAlt from '@/components/DateAlt.vue';
import {
    getSignupRules,
    SignupModel,
    SignupSource,
    createWaitingListSignup,
    isKeyOfSignupModel,
    WaitingListModel,
} from '@/utils/waitingList';
import { Prop } from 'vue-property-decorator';
import ShopModule, {
    ShopModuleValidationError,
} from '@/pages/shop/modules/module';
import Validator, { Rules } from 'validatorjs';

type formErrors = Partial<Record<keyof SignupModel, string>>;

@Component({
    components: { DateAlt },
})
export default class WaitingListDetails extends ShopModule {
    @Prop({ required: true }) waitingList!: WaitingListModel;

    signup: SignupModel | null = null;
    signupRules: Rules = {};
    rules: string[][] = [];
    errors: formErrors = {};

    get allow_quantity(): boolean {
        return !!this.waitingList && this.waitingList.allow_quantity;
    }

    created(): void {
        this.signup = {
            event_id: this.waitingList.event_id,
            shop_id: this.$shop.data.guid,
            email: '',
            first_name: '',
            last_name: '',
            allow_marketing: false,
            quantity: 1,
            source: SignupSource.Shop,
        };

        this.errors = {};

        getSignupRules().then(rules => {
            if (rules) {
                this.signupRules = rules;
            }
        });
    }

    validate(): ShopModuleValidationError | null {
        const errors: formErrors = {};

        if (this.signup) {
            this.errors = {};

            const validator: Validator.Validator<SignupModel> = new Validator(
                this.signup,
                this.signupRules
            );

            validator.check();

            const validationErrors = validator.errors.all();

            if (validationErrors && Object.keys(validationErrors).length > 0) {
                for (const [key, value] of Object.entries(validationErrors)) {
                    if (isKeyOfSignupModel(key, this.signup)) {
                        errors[key] = value[0];
                    }
                }

                const firstError = Object.keys(validationErrors)[0];
                if (
                    firstError &&
                    validationErrors[firstError] &&
                    validationErrors[firstError][0]
                ) {
                    this.errors = errors;

                    return {
                        message: validationErrors[firstError][0] || '',
                        payload: validationErrors,
                    };
                }
            }
        }

        this.errors = errors;
        return null;
    }

    async submit(): Promise<boolean> {
        if (this.signup) {
            try {
                await createWaitingListSignup(
                    this.signup.event_id,
                    this.signup
                );

                return true;
            } catch (e) {
                return false;
            }
        }

        return false;
    }

    quantityInput(): void {
        if (this.errors['quantity']) {
            delete this.errors['quantity'];
        }

        if (this.signup) {
            // For some reason, the input does not properly validate with min and max and let the user decrease/increase regardless of the values
            if (this.signup.quantity < 1) {
                this.signup.quantity = 1;
            }

            if (this.signup.quantity > 32767) {
                this.signup.quantity = 32767;
            }
        }
    }
}
